<template>
    <div class="l_tree_wrapper">
        <!-- <search class="search" @select="handleSelect"></search> -->
        <el-tree
            ref="tree"
            class="tree"
            node-key="id"
            lazy
            :render-content="renderTreeNode"
            :default-expanded-keys="defaultKeys"
            :props="defaultProps"
            :load="loadNode"
            :data="treeData"
            :check-on-click-node="true"
            :highlight-current="true"
            :expand-on-click-node="true"
            @node-click="nodeClick"
        ></el-tree>
    </div>
</template>

<script>
    // import search from "@/components/SelectCar/vehiclePopper";
    export default {
        // components: {
        //     search
        // },
        props: {
            showChannel: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            handleSelect(vehicle) {
                console.log(vehicle);
                // const { vehicleNo } = vehicle;
                // this.expended(vehicleNo);
            },
            // expended(vehicleNo) {
            //     getVehicleNoPath({
            //         vehicleNo: vehicleNo
            //     }).then(res => {
            //         this.$nextTick(() => {
            //             let orgIds = res.data.detail;
            //             orgIds.reverse();
            //             orgIds.push(vehicleNo);
            //             console.log(orgIds);
            //             this.setDefaultKeys(orgIds);
            //         });
            //     });
            // },
            renderTreeNode(h, { node, data }) {
                let queryStr = node.label;

                if (data.isChannel) {
                    return (
                        <span class="custom-tree-node">
                            <input type="checkbox" on-change={this.handleCheckChange.bind(this, data)} style="cursor:pointer" id={data.id} />
                            <span style="font-size:16px;">{data.text}</span>
                        </span>
                    );
                } else {
                    return (
                        <span style="flex: 1; display: flex; align-items: center; justify-content: space-between; font-size: 14px; padding-right: 8px;">
                            <span style={{ color: data.type === 2 && data.online === 1 ? "#36ab60" : "#333333" }}>
                                {data.type == 2 ? <i class="iconfont icon-shipin" width="20" /> : ""}
                                <span>{queryStr}</span>
                            </span>
                        </span>
                    );
                }
            },
            loadNode(node, resolve) {
                let id = "",
                    type = 0;
                if (node.data.id) {
                    id = node.data.id;
                    type = node.data.type;
                }
                if (type === 2) {
                    const grandData = node.data.pData;
                    const pData = node.data;
                    let i,
                        channels = [];
                    for (i = 1; i <= 16; i++) {
                        channels.push({
                            text: `通道${i}`,
                            id: `${pData.id}_${i}`,
                            channelId: i,
                            isLeaf: true,
                            plateNum: grandData.text,
                            deviceId: pData.id,
                            isChannel: true
                        });
                    }
                    resolve(channels);
                } else {
                    // 0-机构 1-车辆 2-设备 3-通道
                    let deviceIds = [];
                    this.$http
                        .post("/base/resource/getTree", {
                            id,
                            type
                        })
                        .then(({ detail }) => {
                            const children = detail[0].children.map(v => {
                                let text, id, type;
                                if (v.type === 0) {
                                    text = v.data.orgNa;
                                    id = v.data.orgId;
                                    type = 0;
                                } else if (v.type === 1) {
                                    text = v.data.plateNum;
                                    id = v.data.vehicleId;
                                    type = 1;
                                } else if (v.type === 2) {
                                    text = v.data.deviceInfo.deviceId;
                                    id = v.data.deviceInfo.deviceId;
                                    type = 2;
                                    deviceIds.push(id);
                                }
                                return {
                                    text,
                                    id,
                                    type,
                                    pData: node.data,
                                    isLeaf: type === 2 && !this.showChannel
                                };
                            });
                            if (deviceIds.length) {
                                this.$http.post("/base/device/getDeviceOnLineStatus", deviceIds).then(res => {
                                    res.detail.forEach(o => {
                                        const child = children.find(c => c.id === o.clientId);
                                        if (child) {
                                            child.online = o.online;
                                        }
                                    });
                                    resolve(children);
                                });
                            } else {
                                resolve(children);
                            }
                        });
                }
            },
            handleCheckChange(data) {
                this.$emit("checkClick", data);
            },
            nodeClick(data) {
                this.$emit("nodeClick", data);
            },
            unChecked(id) {
                //节点设置为不选中
                const ele = document.getElementById(id);
                // ele.setAttribute('checked',false)
                ele.checked = false;
            },
            setChecked(id) {
                //节点设置为选中
                const ele = document.getElementById(id);
                // ele.setAttribute('checked',true)
                ele.checked = true;
            },
            setDefaultKeys(keys) {
                // 设置自动展开树
                if (keys && keys.length) {
                    this.defaultKeys = keys;
                }
            },
            resetDevice(deviceId) {
                //重置选中设备下的通道
                if (deviceId) {
                    for (let index = 0; index < 16; index++) {
                        let id = deviceId + "_" + (index + 1);
                        console.log(id);
                        this.unChecked(id);
                    }
                }
            }
        },
        data() {
            return {
                defaultProps: {
                    label: "text",
                    children: "children",
                    isLeaf: "isLeaf"
                },
                treeData: [],

                defaultKeys: ["0001"]
            };
        }
    };
</script>

<style lang="scss" scoped>
    .l_tree_wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .search {
            height: 36px;
            display: block;
            margin: 15px 7px 10px 7px;
        }
        .el-tree {
            flex: 1;
            width: 320px;
            max-height: 100%;
            padding-bottom: 15px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
</style>
